import { gql, useQuery } from '@apollo/client'
import { EnhancedDateField } from '@dfds-ui/dates'
import { SystemIcon } from '@dfds-ui/icons'
import { ExternalLink, NewWindow, SwapHorziontal } from '@dfds-ui/icons/system'
import { Modal } from '@dfds-ui/modal'
import { LinkButton, Spinner } from '@dfds-ui/react-components'
import { theme } from '@dfds-ui/theme'
import { Text } from '@dfds-ui/typography'
import { css } from '@emotion/react'
import { addDays, addWeeks, format, isSameDay, sub } from 'date-fns'
import { graphql, useStaticQuery } from 'gatsby'
import { isEmpty } from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { isContentfulType } from '../../utils'
import {
  formatLocalizedDate,
  getFirstDateOfWeek,
  getFirstWeekStartsOn,
  getLastDateOfWeek,
  getWeek,
} from '../../utils/dateAndTime'
import { setGlobalAppElement } from '../../utils/setGlobalAppElement'
import { FlexCard } from '../Card'
import { FlexBox } from '../FlexBox'
import { useLocaleContext } from '../LocaleContext'
import CouldNotRetrieveData from './Error'
import PaxTimeTableDirection from './PaxTimeTableDirection'

const getUtcToZonedTime = (date, timezone) =>
  new Date(new Date(date).toLocaleString('en', { timeZone: timezone }))

const SCHEDULE_QUERY = gql`
  query ScheduleQuery(
    $fromDate: String!
    $toDate: String!
    $portCode: String
    $routeCode: String
    $skip: Int
    $take: Int
  ) {
    departures(
      fromDate: $fromDate
      toDate: $toDate
      portCode: $portCode
      routeCode: $routeCode
      skip: $skip
      take: $take
    ) @client {
      departureId
      scheduledDepartureTime
      scheduledArrivalTime
      route {
        departurePort {
          code
          name
          unlocode
          timezone
        }
        arrivalPort {
          code
          name
          unlocode
          timezone
        }
      }
    }
  }
`

const PaxTimeTableSSR = ({ depPort, arrPort, checkInTimeDep }) => {
  const data = useStaticQuery(graphql`
    query PaxTimeTableSSRQuery {
      departuresData {
        departures {
          departureId
          route {
            arrivalPort {
              name
              code
              timezone
            }
            departurePort {
              name
              code
              timezone
            }
          }
          scheduledArrivalTime
          scheduledDepartureTime
        }
      }
    }
  `)

  const { t } = useTranslation()
  const { locale } = useLocaleContext()

  const filterDeparture = (departure) => {
    return (
      departure.route.departurePort.code === depPort &&
      departure.route.arrivalPort.code === arrPort
    )
  }

  return (
    <>
      {data?.departuresData?.departures
        .filter(filterDeparture)
        .map((departure) => {
          const departureDate = getUtcToZonedTime(
            departure.scheduledDepartureTime,
            departure.route.departurePort.timezone,
          )
          const arrivalDate = getUtcToZonedTime(
            departure.scheduledArrivalTime,
            departure.route.arrivalPort.timezone,
          )
          return (
            <div key={departure.departureId} style={{ display: 'none' }}>
              <h3>
                {departureDate.toLocaleDateString(locale, {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </h3>
              <table>
                <tbody>
                  <tr>
                    <th>{t('GATE-CLOSES')}</th>
                    <td>
                      {format(
                        sub(departureDate, {
                          minutes: checkInTimeDep,
                        }),
                        'HH:mm',
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>{t('DEPARTURE')}</th>
                    <td>{format(departureDate, 'HH:mm')}</td>
                  </tr>
                  <tr>
                    <th>{t('ARRIVAL')}</th>
                    <td>{format(arrivalDate, 'HH:mm')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        })}
    </>
  )
}

const PaxTimeTableWrapper = (props) => {
  const [open, setOpen] = useState(false)
  if (props.isModal) {
    setGlobalAppElement()
    return (
      <>
        <div
          role="button"
          onClick={() => setOpen(true)}
          onKeyDown={() => setOpen(true)}
          tabIndex={0}
          css={css`
            color: ${theme.colors.text.secondary.primary};
            margin-bottom: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
          `}
        >
          <span>{props.buttonText}</span>
          <NewWindow
            width="20px"
            height="20px"
            css={css`
              margin-left: 5px;
            `}
            color={theme.colors.text.secondary.main}
          />
        </div>
        <Modal
          heading={props.buttonText}
          noContentPadding
          isOpen={open}
          onRequestClose={() => setOpen(false)}
        >
          {props.children}
        </Modal>
      </>
    )
  }
  return props.children
}

const PaxTimeTable = (props) => {
  const {
    firstDirectionArrivalPort,
    firstDirectionDeparturePort,
    firstDirectionDeparturePortCode,
    gateClosesXMinutesBeforeDeparture,
    secondDirectionDeparturePortCode,
    secondDirectionGateClosesTime,
    link,
  } = props.contentRef || props

  const swapDirection = () => {
    setDirection(
      direction === firstDirectionDeparturePortCode
        ? secondDirectionDeparturePortCode
        : firstDirectionDeparturePortCode,
    )
  }

  const formatSelectedWeek = (date) => {
    // Return start of week - end of week of the selected week e.g. 'Mon 28 Mar - Sun 03 Apr 2022'
    const start = getFirstDateOfWeek(date, locale)
    const end = getLastDateOfWeek(date, locale)
    let startFormat = 'EEE dd'
    if (start.getMonth() !== end.getMonth()) startFormat += ' MMM'
    if (start.getFullYear() !== end.getFullYear()) startFormat += ' yyyy'
    const endFormat = 'EEE dd MMM yyyy'
    return `${formatLocalizedDate(
      start,
      startFormat,
      locale,
    )} - ${formatLocalizedDate(end, endFormat, locale)}`
  }

  const trimSchedule = (data, direction) => {
    const schedule = data?.departures?.map((departure) => ({
      departureId: departure.departureId,
      departureTime: getUtcToZonedTime(
        departure.scheduledDepartureTime,
        departure.route.departurePort.timezone,
      ),
      arrivalTime: getUtcToZonedTime(
        departure.scheduledArrivalTime,
        departure.route.arrivalPort.timezone,
      ),
      departurePortCode: departure.route.departurePort.code,
      arrivalPortCode: departure.route.arrivalPort.code,
    }))

    const currentArrivalPortCode =
      direction === firstDirectionDeparturePortCode
        ? secondDirectionDeparturePortCode
        : firstDirectionDeparturePortCode

    const trimmedSchedule = schedule?.filter(
      (sailing) => sailing.arrivalPortCode === currentArrivalPortCode,
    )
    return trimmedSchedule
  }
  const { locale, localeLower } = useLocaleContext()
  const [direction, setDirection] = useState(firstDirectionDeparturePortCode)
  const { t } = useTranslation()

  const [selectedDate, setSelectedDate] = useState(new Date())

  var startDate, queryFromDate, endDate, queryToDate
  startDate = getFirstDateOfWeek(selectedDate, locale)
  queryFromDate = `${format(startDate, 'yyyy-MM-dd')}T00:00:01Z`
  endDate = getLastDateOfWeek(startDate, locale)
  queryToDate = `${format(endDate, 'yyyy-MM-dd')}T23:59:59Z`

  const { loading, data, error } = useQuery(SCHEDULE_QUERY, {
    variables: {
      fromDate: queryFromDate,
      toDate: queryToDate,
      portCode: direction,
    },
  })

  // Preload previous week
  const queryPrevWeekFromDate = `${format(
    addWeeks(startDate, -1),
    'yyyy-MM-dd',
  )}T00:00:01Z`
  const queryPrevWeekToDate = `${format(
    addWeeks(endDate, -1),
    'yyyy-MM-dd',
  )}T23:59:59Z`
  useQuery(SCHEDULE_QUERY, {
    variables: {
      fromDate: queryPrevWeekFromDate,
      toDate: queryPrevWeekToDate,
      portCode: direction,
    },
  })

  // Preload next week
  const queryNextWeekFromDate = `${format(
    addWeeks(startDate, 1),
    'yyyy-MM-dd',
  )}T00:00:01Z`
  const queryNextWeekToDate = `${format(
    addWeeks(endDate, 1),
    'yyyy-MM-dd',
  )}T23:59:59Z`
  const nextWeekData = useQuery(SCHEDULE_QUERY, {
    variables: {
      fromDate: queryNextWeekFromDate,
      toDate: queryNextWeekToDate,
      portCode: direction,
    },
  }).data

  // Preload opposite direction
  useQuery(SCHEDULE_QUERY, {
    variables: {
      fromDate: queryFromDate,
      toDate: queryToDate,
      portCode:
        direction === firstDirectionDeparturePortCode
          ? secondDirectionDeparturePortCode
          : firstDirectionDeparturePortCode,
    },
  })

  // if (loading) return <div>Loading</div>

  const trimmedSchedule = trimSchedule(data, direction)

  // Calculate next sailing date if no sailings are available on selected date.
  // If no more sailings are found, it is assumed that we are beyond was has been scheduled.
  let nextSailingDate = undefined
  if (
    // If no departures on selected date
    trimmedSchedule &&
    !trimmedSchedule.some((departure) =>
      isSameDay(departure.departureTime, selectedDate),
    )
  ) {
    // Try to find the next sailing within this week
    const nextSailingThisWeek = trimmedSchedule.find(
      (departure) => departure.departureTime >= addDays(selectedDate, 1),
    )?.departureTime
    if (nextSailingThisWeek) nextSailingDate = new Date(nextSailingThisWeek)
    else {
      // If no sailing was found within this week, try next week
      const nextWeekTrimmedSchedule = trimSchedule(nextWeekData, direction)
      let firstSailingNextWeek =
        nextWeekTrimmedSchedule &&
        !isEmpty(nextWeekTrimmedSchedule) &&
        nextWeekTrimmedSchedule[0].departureTime
      if (firstSailingNextWeek) nextSailingDate = new Date(firstSailingNextWeek)
    }
  }

  return (
    <PaxTimeTableWrapper {...props}>
      <FlexCard width={props.width}>
        <FlexBox
          css={css`
            display: inline-flex;
            align-items: center;
            margin-bottom: ${theme.spacing.s};
            cursor: pointer;
          `}
          onClick={swapDirection}
        >
          <Text
            styledAs={'smallHeadline'}
            css={css`
              margin: 0;
            `}
          >
            {direction === firstDirectionDeparturePortCode
              ? `${firstDirectionDeparturePort}`
              : `${firstDirectionArrivalPort}`}
          </Text>
          <SwapHorziontal
            width="35px"
            height="35px"
            color={theme.colors.text.secondary.primary}
            data-e2e="timetableswap"
            css={css`
              margin: 0 ${theme.spacing.xs};
            `}
          />
          <Text
            styledAs={'smallHeadline'}
            css={css`
              margin: 0;
            `}
          >
            {direction === firstDirectionDeparturePortCode
              ? `${firstDirectionArrivalPort}`
              : `${firstDirectionDeparturePort}`}
          </Text>
        </FlexBox>
        <Text
          styledAs={'label'}
          css={css`
            margin: 0;
          `}
        >
          {`${t('WEEK')} ${getWeek(startDate, locale)}`}
        </Text>
        <EnhancedDateField
          value={selectedDate}
          locale={localeLower === 'en' ? 'en-gb' : localeLower}
          onDateFormatting={formatSelectedWeek}
          datePickerProps={{
            onDayClick: (day) => setSelectedDate(new Date(day)),
            showWeekNumbers: getFirstWeekStartsOn(locale) === 4, // Hide week numbers until DayPicker supports other week numbering calculation than the one from ISO 8601
            enableOutsideDaysClick: false,
          }}
        />
        <FlexBox
          justifySpaceBetween
          css={css`
            margin: ${theme.spacing.s} 0;
          `}
        >
          <LinkButton
            size="small"
            icon={<SystemIcon icon="ChevronLeft" />}
            iconAlign="left"
            onClick={() => setSelectedDate(addWeeks(selectedDate, -1))}
          >
            {t('PREVIOUS-WEEK')}
          </LinkButton>
          <LinkButton
            size="small"
            icon={<SystemIcon icon="ChevronRight" />}
            iconAlign="right"
            onClick={() => setSelectedDate(addWeeks(selectedDate, 1))}
          >
            {t('NEXT-WEEK')}
          </LinkButton>
        </FlexBox>
        {loading && (
          <FlexBox style={{ justifyContent: 'center' }}>
            <Spinner
              css={css`
                display: flex;
                align-items: center;
                width: auto;
              `}
            />
          </FlexBox>
        )}
        <PaxTimeTableSSR
          depPort={firstDirectionDeparturePortCode}
          arrPort={secondDirectionDeparturePortCode}
          checkInTimeDep={gateClosesXMinutesBeforeDeparture}
        />
        {error && <CouldNotRetrieveData />}
        {!loading && !error && data.departures && (
          <PaxTimeTableDirection
            schedule={trimmedSchedule}
            startDate={startDate}
            selectedDate={selectedDate}
            nextSailingDate={nextSailingDate}
            selectedDateChanged={setSelectedDate}
            gateClosesXMinutesBeforeDeparture={
              direction === firstDirectionDeparturePortCode
                ? gateClosesXMinutesBeforeDeparture
                : secondDirectionGateClosesTime
            }
          />
        )}
        <Text
          css={css`
            margin: ${theme.spacing.m} 0 0 0;
          `}
        >
          {t('TIMETABLE-TIME-DISCLAIMER')}
        </Text>
        <Text>{t('TIMETABLE-CHANGE-DISCLAIMER')}</Text>
        {link && (
          <a
            href={
              isContentfulType(link.__typename, 'contentful_Url')
                ? link.url
                : link.asset.url
            }
            target="_blank"
          >
            <span>{link.linkText}</span>
            <ExternalLink
              width={'24px'}
              height={'20px'}
              color={theme.colors.text.secondary.primary}
              css={css`
                margin-left: 10px;
              `}
            />
          </a>
        )}
      </FlexCard>
    </PaxTimeTableWrapper>
  )
}

export default PaxTimeTable
